











































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { VSelect } from "vuetify/lib";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import {
  Signature,
  SignatureType,
} from "../../../store/modules/measureProcess/types";

@Component({
  components: { VSelect, AUserChip, ADateChip },
})
export default class AMeasureProcessStepContainer extends Vue {
  verifyComment = "";
  @Prop({
    default: false,
    type: Boolean,
  })
  readonly current!: boolean;

  @Prop({
    type: String,
  })
  readonly title!: string;

  @Prop({
    type: Number,
    default: null,
  })
  readonly attachments!: number | null;

  @Prop({
    type: Number,
    default: null,
  })
  readonly comments!: number | null;

  @Prop({
    type: Object,
    default: null,
  })
  readonly user!: IUserRef | null;

  @Prop({
    type: Date,
    default: null,
  })
  readonly date!: Date | null;

  @Prop({
    type: String,
  })
  readonly color!: string;

  @Prop({
    type: Signature,
  })
  readonly signature!: Signature | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly isVerifying!: boolean;

  acceptStep() {
    this.emitVerify(SignatureType.Verified);
  }
  rejectStep() {
    this.emitVerify(SignatureType.Rejected);
  }

  emitVerify(type: SignatureType) {
    const signature = new Signature(type, new Date(), {
      id: this.$user.id(),
      displayName: this.$user.displayName(),
    });
    console.log("VERIFY", signature);
    this.$emit("verify", { signature, comment: this.verifyComment });
  }
}
